import axios, { AxiosError } from 'axios'
import z from 'zod'
import {
  CandidateStatus,
  Company,
  ContactUs,
  Education,
  Experience,
  ExperienceCreate,
  Job,
  JobApplication,
  JobApplicationCreate,
  JobApplicationWithJob,
  JobCreate,
  JobWithApplicationsAndUsers,
  JobWithCompany,
  LoginRegisterResponse,
  LoginRequest,
  Profile,
  RegisterRequest,
  User,
  UserType,
} from '../schemas/schemas'
import { clearTokens, getAccessToken, getRefreshToken, setAccessToken, setRememberMe } from '../utils/auth'
import { Filters } from '../hooks/useSearchFilters'
import { JobApply } from '../pages/JobDetailsPage/JobDetailsPage'
import { ExperienceEdit } from '../pages/ProfilePage/ManageExperiences'
import { EducationCreate, EducationEdit } from '../pages/ProfilePage/ManageEducations'

const ARRAY_DELIMITER = ';'

// to simulate API delays, before API is ready to be used
export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

// Setting withCredentials to true globally to be able to work with Cookies
axios.defaults.withCredentials = true

const baseURL =
  process.env.REACT_APP_API ||
  (process.env.NODE_ENV === 'development'
    ? `http://${window.location.hostname}:8080`
    : `https://${window.location.hostname}`)

// Create an instance of axios with a base URL
export const axiosInstance = axios.create({
  baseURL: baseURL,
})

axiosInstance.interceptors.request.use(
  config => {
    const token = getAccessToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

// refresh token logic
axiosInstance.interceptors.response.use(
  async response => response,
  async error => {
    const originalRequest = error.config

    // Skip interceptor for the refresh token request
    if (originalRequest.headers['x-refresh-token-request']) {
      return Promise.reject(error)
    }

    // Check if the error is 401 or 403 and retry has not been attempted yet
    if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = getRefreshToken()

      // If no refresh token is available, clear tokens and redirect to login
      if (!refreshToken) {
        // console.error('No refresh token available.')
        // clearTokens()
        // window.location.href = '/' // redirect to login
        return Promise.reject(error)
      }

      try {
        const res = await axiosInstance.post(
          '/api/auth/refresh',
          { refreshToken: refreshToken },
          {
            headers: { 'x-refresh-token-request': 'true' }, // Add custom header to identify this request
          },
        )

        if (res.status === 201 || res.status === 200) {
          const newToken = res.data.accessToken
          setAccessToken(newToken)

          // Update the authorization header
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${newToken}`
          originalRequest.headers.Authorization = `Bearer ${newToken}`

          // Retry the original request with the new token
          return await axiosInstance(originalRequest)
        } else {
          console.error('Failed to refresh token:', res.status, res.data)
          clearTokens()
          window.location.href = '/' // redirect to login
          return await Promise.reject(new Error('Failed to refresh token'))
        }
      } catch (err) {
        console.error('Error during token refresh:', err)
        clearTokens()
        window.location.href = '/' // redirect to login
        return Promise.reject(err)
      }
    } else if (originalRequest._retry) {
      console.error('Retry failed, clearing tokens and redirecting to login.')
      clearTokens()
      window.location.href = '/' // redirect to login
      return Promise.reject(error)
    }

    return Promise.reject(error)
  },
)

// axiosInstance.interceptors.response.use(
//   response => response,
//   error => {
//     const originalRequest = error.config
//     console.log('originalRequest._retry before check:', originalRequest._retry)

//     if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
//       originalRequest._retry = true // mark it so that we don't try indefinitely

//       console.log('Setting originalRequest._retry to true:', originalRequest._retry)

//       return axiosInstance
//         .post('/api/auth/refresh', {
//           refreshToken: getRefreshToken(),
//         })
//         .then(res => {
//           if (res.status === 201 || res.status === 200) {
//             const newToken = res.data.accessToken
//             setAccessToken(newToken)

//             // Update the authorization header
//             axiosInstance.defaults.headers.common.Authorization = `Bearer ${newToken}`
//             originalRequest.headers.Authorization = `Bearer ${newToken}`

//             // Retry the original request with the new token
//             return axiosInstance(originalRequest)
//           } else {
//             console.error('Failed to refresh token:', res.status, res.data)
//             clearTokens()
//             window.location.href = '/' // redirect to login
//             return Promise.reject(error)
//           }
//         })
//         .catch(err => {
//           console.error('Error during token refresh:', err)
//           clearTokens()
//           window.location.href = '/' // redirect to login
//           return Promise.reject(err)
//         })
//     } else if (originalRequest._retry) {
//       console.error('Retry failed, clearing tokens and redirecting to login.')
//       // If retry fails, clear tokens and redirect to login
//       clearTokens()
//       window.location.href = '/' // redirect to login
//     }
//     return Promise.reject(error)
//   },
// )

// axios.interceptors.response.use(response => response, error => {
//   const errorCode = error.response.headers.error_code;
//   if (errorCode === 'TOKEN_EXPIRED') {
//       // Handle token expiration (e.g., refresh token)
//   } else if (errorCode === 'FORBIDDEN_ACTION') {
//       // Handle forbidden actions
//   }
//   return Promise.reject(error);
// });

export const loginUser = async (formData: LoginRequest): Promise<LoginRegisterResponse> => {
  const { data } = await axiosInstance.post<LoginRegisterResponse>('/api/auth/login', formData)
  setRememberMe(formData.rememberMe)
  return data
}

export const logoutUser = async (): Promise<void> => {
  // await axiosInstance.post('/api/logout')
}

export const registerUser = async (formData: RegisterRequest): Promise<LoginRegisterResponse> => {
  const response = await axiosInstance.post('/api/auth/signup', formData)
  return response.data
}

export const getCurrentUser = async (): Promise<User> => {
  // Fetch the basic user information
  try {
    const { data: user } = await axiosInstance.get<User>('/api/auth/me')
    // adjusting to new API
    if (user.role === UserType.EMPLOYER) {
      user.isEmployer = true
    }
    if (user.company) {
      user.companyId = user.company.id
    }
    return user
  } catch (error) {
    // if user is authorized but for some reason doesn't exist
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError
      if (axiosError?.response?.status === 404) {
        clearTokens()
        window.location.href = '/' // redirect to login
      }
    }
    throw error
  }
}

export const getProfile = async (userId: number): Promise<Profile> => {
  // Initiate all requests in parallel
  const [userResponse, experiencesResponse, educationsResponse] = await Promise.all([
    axiosInstance.get(`/api/users/${userId}`),
    axiosInstance.get(`/api/experiences?userId=${userId}`),
    axiosInstance.get(`/api/educations?userId=${userId}`),
  ])

  // Extract data from responses
  const user: Profile = {
    ...(userResponse.data as User),
    experiences: experiencesResponse.data,
    educations: educationsResponse.data,
  }

  return user
}

export const fetchEmployerCompany = async (companyId: number): Promise<Company> => {
  const { data: company } = await axiosInstance.get(`/api/companies/${companyId}`)
  return company
}

export async function getJobWithCompany(id: number): Promise<JobWithCompany> {
  const { data: job } = await axiosInstance.get(`/api/jobs/${id}`)
  if (!job.company) {
    const { data: company } = await axiosInstance.get(`/api/companies/${job.companyId}`)
    job.company = company
  }
  // TODO: pull certificates
  return job
}

export async function getJob(id: number): Promise<Job> {
  const { data: job } = await axiosInstance.get(`/api/jobs/${id}`)
  return job
}

export const getJobTitles = async (): Promise<string[]> => {
  const JobTitlesSchema = z.array(z.string())
  const res = await axiosInstance.get(`/api/autocomplete?type=jobtitle`)
  return JobTitlesSchema.parse(res.data)
}

const fetchJobsByEmployer = async (employerId: number): Promise<Job[]> => {
  const response = await axiosInstance.get(`/api/jobs?creatorId=${employerId}`)
  return response.data
}

// Update the status of an application
export const updateApplicationStatus = async ({
  applicationId,
  newStatus,
}: {
  applicationId: number
  newStatus: CandidateStatus
}): Promise<void> => {
  await axiosInstance.patch(`/api/applications/${applicationId}`, {
    status: newStatus,
  })
}

export const getApplicationsByCandidateId = async (candidateId: number): Promise<JobApplicationWithJob[]> => {
  const getApplications = async (id: number): Promise<JobApplication[]> => {
    const response = await axiosInstance.get(`/api/applications?candidateId=${id}`)
    return response.data
  }
  const getJobs = async (ids: number[]): Promise<Job[]> => {
    const response = await axiosInstance.get(`/api/jobs?id=${ids.join(ARRAY_DELIMITER)}`)
    return response.data
  }

  const applications = await getApplications(candidateId)
  const jobIds = applications.map(it => it.jobId)
  const jobs = await getJobs(jobIds)

  // Merge applications with their corresponding user details
  const finalData = applications.map(application => ({
    ...application,
    job: jobs.find(job => job.id === application.jobId),
  })) as JobApplicationWithJob[]

  return finalData
}

export async function fetchJobsApplicationsAndUsers(employerId: number): Promise<JobWithApplicationsAndUsers[]> {
  // // Fetch jobs for the employer
  // const jobs = (await fetchJobsByEmployer(employerId)) as JobWithApplicationsAndUsers[]

  // // Extract job IDs and fetch applications
  // const jobIds = jobs.map(job => job.id)
  // const applications = jobIds.length ? await fetchApplicationsByJobIds(jobIds) : []

  // // Extract user IDs and fetch user details
  // const userIds = applications.map(app => app.candidateId)
  // const users: User[] = userIds.length ? await fetchUsersByIds([...new Set(userIds)]) : [] // Remove duplicates

  // // Merge applications with their corresponding user details
  // const applicationsWithUsers = applications.map(application => ({
  //   ...application,
  //   candidate: users.find(user => user.id === application.candidateId),
  // })) as JobApplicationWithCandidate[]

  // // Merge jobs with their corresponding applications
  // const jobsWithApplications = jobs.map(job => ({
  //   ...job,
  //   applications: applicationsWithUsers.filter(application => application.jobId === job.id),
  // }))

  // return jobsWithApplications as JobWithApplicationsAndUsers[]
  return (await fetchJobsByEmployer(employerId)) as JobWithApplicationsAndUsers[]
}

export const updateCompany = async (formData: Company): Promise<void> => {
  const companyId = formData.id
  const response = await axiosInstance.patch(`/api/companies/${companyId}`, formData)
  return response.data
}

export const uploadFileAndGetURL = async (fileName: string, file: Blob, fileType: string): Promise<string> => {
  try {
    // Step 1: Request a signed URL from the backend
    const {
      data: { signedUrl, publicUrl },
    } = await axiosInstance.get('/api/generate-signed-url', {
      params: { file_name: fileName, content_type: fileType },
    })

    // Step 2: Upload the file to Google Cloud Storage using the signed URL
    await axiosInstance.put(signedUrl, file, {
      headers: {
        'Content-Type': fileType,
      },
      withCredentials: false,
    })

    return publicUrl
  } catch (error) {
    console.error('Error uploading file:', error)
    throw new Error('Failed to upload file.')
  }
}

export const createJob = async (job: JobCreate): Promise<Job> => {
  const response = await axiosInstance.post(`/api/jobs`, job)
  return response.data
}

export const updateJob = async (job: Job): Promise<Job> => {
  const jobId = job.id
  const response = await axiosInstance.patch(`/api/jobs/${jobId}`, job)
  return response.data
}

type QueryParams = {
  [key: string]: string | number | boolean | (string | number | boolean)[]
}

function buildQueryString(params: QueryParams) {
  const queryParams = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        queryParams.append(key, value.join(ARRAY_DELIMITER))
      } else {
        queryParams.append(key, value.toString())
      }
    }
  })
  return queryParams.toString()
}

function convertFiltersToQueryParams(filters: Filters, pageSize = 7): QueryParams {
  const params: QueryParams = {}

  if (filters.matchMe) {
    params.matchMe = filters.matchMe
  }

  if (filters.industry) {
    params.industry = filters.industry
  }
  if (filters.jobType) {
    params.jobType = filters.jobType
  }
  if (filters.posted) {
    params.posted = filters.posted
  }

  if (filters.pay) {
    params.payMin = filters.pay[0]
    params.payMax = filters.pay[1]
  }

  if (filters.experience && filters.experience.length > 0) {
    params.experience = filters.experience
  }

  if (filters.title) {
    params.title = filters.title
  }
  if (filters.location) {
    params.location = filters.location
  }
  if (filters.sortBy) {
    params.sortBy = filters.sortBy
  }
  if (filters.page) {
    params.page = filters.page
  }

  params.page_size = pageSize

  return params
}

export const getCompaniesByIds = async (companyIds: number[]): Promise<Company[]> => {
  const { data: companies } = await axiosInstance.get(`/api/companies?id=${companyIds.join(ARRAY_DELIMITER)}`)
  return companies
}

interface JobSearchResult {
  total?: number
  pages?: number
  page_size?: number
  data?: JobWithCompany[]
}

export const searchJobs = async (filters: Filters): Promise<JobSearchResult> => {
  const queryString = filters ? buildQueryString(convertFiltersToQueryParams(filters)) : ''
  const { data: searchResult } = await axiosInstance.get(`/api/search?${queryString}`)
  return searchResult
}

export const similarJobs = async (): Promise<JobWithCompany[]> => {
  // for now will use search endpoint
  const { data: searchResult } = await axiosInstance.get(`/api/search?page_size=3`)
  return searchResult.data
}

export const submitApplication = async ({
  jobApply,
  user,
}: {
  jobApply: JobApply
  user: User
}): Promise<JobApplication> => {
  // upload CV
  let fileUrl: string | undefined
  if (jobApply.file) {
    try {
      fileUrl = await uploadFileAndGetURL(jobApply.file.name, jobApply.file, jobApply.file.type)
    } catch (error) {
      console.error('Error uploading resume :', error)
    }
  }

  const application: JobApplicationCreate = {
    status: CandidateStatus.New,
    email: jobApply.email,
    resume: fileUrl,
    jobId: jobApply.jobId,
    candidateId: user.id,
    relatedExperiences: jobApply.jobItemsList.map(it => ({
      position: it.position,
      company: it.company,
    })),
  }
  const { data } = await axiosInstance.post(`/api/applications`, application)
  return data
}

export const updateUser = async (formData: User): Promise<void> => {
  const { id, ...userData } = formData
  const response = await axiosInstance.patch(`/api/users/${id}`, userData)
  return response.data
}

export const updateMyUser = async (formData: User): Promise<void> => {
  const { id, ...userData } = formData
  const response = await axiosInstance.patch(`/api/users/${id}`, userData)
  return response.data
}

export const deleteJob = async (jobId: number): Promise<void> => {
  await axiosInstance.delete(`/api/jobs/${jobId}`)
}

export const forgotPasswordRequest = async (email: string): Promise<void> => {
  try {
    await axiosInstance.post('/api/auth/forgot-password/request', { email })
  } catch (error) {
    console.error('Error sending verification code:', error)
    throw error
  }
}

interface VerifyResponse {
  token: string
  message: string
}
export const forgotPasswordVerify = async (data: { email: string; code: string }): Promise<VerifyResponse> => {
  try {
    const { data: response } = await axiosInstance.post('/api/auth/forgot-password/verify', data)
    return response
  } catch (error) {
    console.error('Error verifying code:', error)
    throw error
  }
}

export const forgotPasswordReset = async (data: { token: string; newPassword: string }): Promise<void> => {
  try {
    await axiosInstance.post('/api/auth/forgot-password/reset', data)
  } catch (error) {
    console.error('Error when resetting password:', error)
    throw error
  }
}

export const registerResendCode = async (email: string): Promise<void> => {
  try {
    await axiosInstance.post('/api/auth/signup/resend-code', { email })
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

export const registerVerify = async (data: { email: string; code: string }): Promise<LoginRegisterResponse> => {
  try {
    const response = await axiosInstance.post('/api/auth/verify', data)
    return response.data
  } catch (error) {
    console.error('Error:', error)
    throw error
  }
}

export const createEducation = async (newData: EducationCreate): Promise<Education> => {
  const response = await axiosInstance.post(`/api/educations`, newData)
  return response.data
}

export const updateEducation = async (updatedData: EducationEdit): Promise<Education> => {
  const response = await axiosInstance.patch(`/api/educations/${updatedData.id}`, updatedData)
  return response.data
}

export const deleteEducation = async (id: number): Promise<void> => {
  await axiosInstance.delete(`/api/educations/${id}`)
}

export const createExperience = async (newData: ExperienceCreate): Promise<Experience> => {
  const response = await axiosInstance.post(`/api/experiences`, newData)
  return response.data
}

export const updateExperience = async (updatedData: ExperienceEdit): Promise<Experience> => {
  const response = await axiosInstance.patch(`/api/experiences/${updatedData.id}`, updatedData)
  return response.data
}

export const deleteExperience = async (id: number): Promise<void> => {
  await axiosInstance.delete(`/api/experiences/${id}`)
}

export const sendContactUs = async (data: ContactUs): Promise<void> => {
  const response = await axiosInstance.post(`/api/contact-us`, data)
  return response.data
}

import Dialog from 'rc-dialog'
import 'rc-dialog/assets/index.css'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { uploadFileAndGetURL } from '../../api/api'
import userPic from '../../assets/user_candidate_Profile1.png'
import Button from '../../components/Button/Button'
import { Color } from '../../components/Dropdown/Dropdown'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import Icon from '../../components/Icon/Icon'
import Input from '../../components/Input/Input'
import Spinner from '../../components/Spinner/Spinner'
import Timeline from '../../components/Timeline/Timeline'
import Uploader from '../../components/Uploader/Uploader'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useCurrentUserMutation } from '../../hooks/useCurrentUserMutation'
import { useUser } from '../../hooks/useUser'
import { User, UserSchema } from '../../schemas/schemas'
import { formatDateRange } from '../../utils/date'
import ManageEducations from './ManageEducations'
import ManageExperiences from './ManageExperiences'
import ManagePreferences from './ManagePreferences'
import styles from './ProfilePage.module.scss'
import defaultExperienceImg from '../../assets/work.png'

export default function Profile() {
  const userId = Number(useParams().userId)
  const { data: currentUser, isFetched: isCurrentUserFetched } = useCurrentUser()
  const { isPending, isError, data: user, error, isFetched: isUserFetched } = useUser(userId)

  const currentUserMutation = useCurrentUserMutation(userId)

  const isMe = isCurrentUserFetched && isUserFetched && currentUser?.id === user?.id

  const [modalProfileOpen, setModalProfileOpen] = useState(false)
  const [modalAboutOpen, setModalAboutOpen] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [title, setTitle] = useState('')
  const [location, setLocation] = useState('')
  const [about, setAbout] = useState('')
  const [modalExperiencesOpen, setModalExperiencesOpen] = useState(false)
  const [modalEducationOpen, setModalEducationOpen] = useState(false)
  const [modalJobPrefOpen, setModalJobPrefOpen] = useState(false)
  const [croppedLogoUrl, setCroppedLogoUrl] = useState('')

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName || '')
      setLastName(user.lastName || '')
      setTitle(user.title || '')
      setLocation(user.location || '')
      setAbout(user.about || '')
      setCroppedLogoUrl(user.avatar || '')
    }
  }, [user])

  if (isPending) return <span>Loading...</span>
  if (isError) return <ErrorMessage error={error} />

  const handleProfileSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    try {
      const updatedData: User = {
        id: userId,
        firstName,
        lastName,
        title,
        location,
      }
      UserSchema.parse(updatedData)
      await currentUserMutation.mutateAsync(updatedData)
      setModalProfileOpen(false)
    } catch (err) {}
  }

  const handleAboutSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    try {
      const updatedData: User = {
        id: userId,
        about,
      }
      UserSchema.parse(updatedData)
      await currentUserMutation.mutateAsync(updatedData)
      setModalAboutOpen(false)
    } catch (err) {}
  }

  const updateLogo = async (fileName: string, file: Blob, fileType: string) => {
    if (!file) {
      alert('Please select a file to upload.')
      return
    }

    try {
      const avatarUrl = await uploadFileAndGetURL(fileName, file, fileType)

      // Step 3: Update the user's avatar URL in the backend
      const updatedData: User = { id: userId, avatar: avatarUrl }
      currentUserMutation.mutate(updatedData)
    } catch (updateLogoError) {
      console.error('Error updating avatar:', updateLogoError)
    }
  }

  const handleCropComplete = (blobUrl: string, blob: Blob, fileType: string) => {
    setCroppedLogoUrl(blobUrl)
    updateLogo(`profile-avatar-${user?.id}`, blob, fileType)
  }
  const handleChangeLogo = (e: SyntheticEvent) => {
    e.preventDefault()
    setCroppedLogoUrl('')
  }

  return (
    <div className={styles.page}>
      {currentUserMutation.isPending && <Spinner />}
      <Dialog
        title='Edit your contact information'
        onClose={() => setModalProfileOpen(false)}
        visible={modalProfileOpen}
        className={styles.center}
        maskAnimation='fade'
      >
        <form onSubmit={handleProfileSubmit}>
          <div className={styles.contact}>
            <div className={styles.contactDetails}>
              <p>First Name</p>
              <Input value={firstName} bgColor={Color.Gray} onChange={e => setFirstName(e.target.value)} />
            </div>
            <div className={styles.contactDetails}>
              <p>Last Name</p>
              <Input value={lastName} bgColor={Color.Gray} onChange={e => setLastName(e.target.value)} />
            </div>
          </div>
          <div className={styles.contactDetails}>
            <p>Your Profile Title</p>
            <Input value={title} bgColor={Color.Gray} onChange={e => setTitle(e.target.value)} />
          </div>
          <div className={styles.contactDetails}>
            <p>Your Location</p>
            <Input value={location} bgColor={Color.Gray} onChange={e => setLocation(e.target.value)} />
          </div>
          <div className={styles.footer}>
            <Button primary medium text='Save' />
          </div>
        </form>
      </Dialog>
      <div className={styles.top}>
        {!isMe && <img className={styles.profilePicture} src={user.avatar || userPic} width={100} height={100} />}

        {/* Avatar upload  */}
        {isMe && (
          <>
            {!croppedLogoUrl && (
              <Uploader
                uploadCircleClass={styles.size100}
                placeholderText='Upload avatar'
                onCropComplete={handleCropComplete}
              />
            )}
            {croppedLogoUrl && (
              <div className={styles.croppedLogoBlock}>
                <img src={croppedLogoUrl} alt='Cropped' className={styles.profilePicture} width={100} height={100} />
                <button className={styles.buttonLogo} onClick={handleChangeLogo}>
                  Change
                </button>
              </div>
            )}
          </>
        )}

        <div className={styles.profileDetails}>
          <div className={styles.name}>
            {user.firstName} {user.lastName}
            {isMe && (
              <div className={styles.roundBlueBackground}>
                <Icon iconName={'pencilEdit'} size={20} onClick={() => setModalProfileOpen(true)} />
              </div>
            )}
          </div>

          <div className={styles.location}>
            {user.location && (
              <>
                <Icon iconName={'arrowOrange'} size={12} />
                <span>{user.location}</span>
              </>
            )}
            <div>{user.title}</div>
          </div>
        </div>
        {/* <Button primary medium text='Upload Resume' /> */}
      </div>
      <div className={styles.main}>
        <div className={styles.mainCenter}>
          <div className={styles.marginRight}>
            <div className={styles.dialogAboutModal}>
              <Dialog
                title='Edit about section'
                onClose={() => setModalAboutOpen(false)}
                visible={modalAboutOpen}
                className={styles.dialogAboutModal}
                maskAnimation='fade'
                style={{ top: '30%' }}
              >
                <form onSubmit={handleAboutSubmit}>
                  <div className={styles.title}>About</div>
                  <div className={styles.contactDetails}>
                    <div className={styles.textareaContainer}>
                      <textarea
                        className={`${styles.textarea} ${styles.textareaExpanding}`}
                        value={about}
                        onChange={e => setAbout(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.footer}>
                    <Button primary medium text='Save' />
                  </div>
                </form>
              </Dialog>
            </div>
            <div className={styles.about}>
              {isMe && (
                <div className={styles.roundBlueBackground}>
                  <Icon iconName={'pencilEdit'} size={20} onClick={() => setModalAboutOpen(true)} />
                </div>
              )}
              <div className={styles.title}>About</div>
              <div className={styles.content}>{user.about}</div>
            </div>

            {modalExperiencesOpen && (
              <ManageExperiences userId={userId} onClose={() => setModalExperiencesOpen(false)} />
            )}

            {user.experiences && (
              <Timeline
                title='Experience'
                items={user.experiences.map(exp => ({
                  id: exp.id,
                  logo: defaultExperienceImg,
                  title: exp.position,
                  dateRange: formatDateRange(exp.startDate, exp.endDate),
                  name: exp.company,
                  text: exp.description,
                }))}
                editable={isMe}
                onEditExperiences={() => setModalExperiencesOpen(true)}
              />
            )}

            {modalEducationOpen && <ManageEducations userId={userId} onClose={() => setModalEducationOpen(false)} />}

            {user.educations && (
              <Timeline
                title='Education'
                items={user.educations.map(edu => ({
                  id: edu.id,
                  logo: 'https://cdn-icons-png.flaticon.com/512/174/174840.png',
                  title: edu.degree,
                  dateRange: formatDateRange(edu.startDate, edu.endDate),
                  name: edu.institution,
                  text: edu.description,
                }))}
                editable={isMe}
                onEditEducation={() => setModalEducationOpen(true)}
              />
            )}
          </div>

          <div className={styles.certificates}>
            {/* <h2>Acquired certificates</h2>
            {isMe && (
              <div className={styles.roundBlueBackground}>
                <Icon iconName={'pencilEdit'} size={20} />
              </div>
            )} */}
            {/* <Carousel items={user.certificates || []} /> */}
          </div>
        </div>
        <div>
          <div className={styles.mainRight}>
            <div className={styles.preferences}>
              {isMe && (
                <div className={styles.pencil}>
                  <Icon iconName={'pencilEdit'} size={20} onClick={() => setModalJobPrefOpen(true)} />
                </div>
              )}
              {modalJobPrefOpen && <ManagePreferences userId={userId} onClose={() => setModalJobPrefOpen(false)} />}
              <div className={styles.paragraphTitle}>Job preferences</div>

              <div className={styles.component}>
                <div className={styles.title}>Job titles</div>
                <div className={styles.badges}>
                  {user.preferencesTitles && user.preferencesTitles.map((it, index) => <li key={index}>{it}</li>)}
                </div>
              </div>

              <div className={styles.component}>
                <div className={styles.title}>Workplaces</div>
                <div className={styles.badges}>
                  {user.preferencesWorkplaces?.map((it, index) => (
                    <li key={index}>{it}</li>
                  ))}
                </div>
              </div>
              <div className={styles.component}>
                <div className={styles.title}>Job locations (On-site)</div>
                <div className={styles.badges}>
                  {user.preferencesLocations?.map((it, index) => (
                    <li key={index}>{it}</li>
                  ))}
                </div>
              </div>
              <div className={styles.component}>
                <div className={styles.title}>Job types</div>
                <div className={styles.badges}>
                  {user.preferencesTypes?.map((it, index) => (
                    <li key={index}>{it}</li>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.skills}>
            {isMe && (
              <div className={styles.pencil}>
                <Icon iconName={'pencilEdit'} size={20} onClick={() => setModalJobPrefOpen(true)} />
              </div>
            )}
            <div className={styles.paragraphTitle}>Professional Skills</div>
            <div className={styles.badges}>
              {user.skills?.map((it, index) => (
                <li key={index}>{it}</li>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

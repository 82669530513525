import { ChangeEvent, useCallback, useEffect, useState, useRef } from 'react'
import classnames from 'classnames'
import styles from './RangeSlider.module.scss'

interface Props {
  min: number
  max: number
  value: [number, number]
  onChange: (val: [number, number]) => void
}

export default function RangeSlider({ min, max, value, onChange }: Props) {
  const [minVal, setMinVal] = useState(value[0])
  const [maxVal, setMaxVal] = useState(value[1])
  const minValRef = useRef<HTMLInputElement>(null)
  const maxValRef = useRef<HTMLInputElement>(null)
  const range = useRef<HTMLDivElement>(null)

  // Helper function to format numbers as currency
  const formatNumber = (num: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(num)
  }

  // Convert to percentage
  const getPercent = useCallback((v: number) => Math.round(((v - min) / (max - min)) * 100), [min, max])

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal)
      const maxPercent = getPercent(+maxValRef.current.value) // Precede with '+' to convert the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [minVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value)
      const maxPercent = getPercent(maxVal)

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [maxVal, getPercent])

  useEffect(() => {
    setMinVal(value[0])
    setMaxVal(value[1])
  }, [value])

  const inputClass1 = classnames({
    [styles.thumb]: true,
    [styles.thumbZindex3]: true,
    [styles.thumbZindex5]: minVal > max - 100,
  })

  const inputClass2 = classnames({
    [styles.thumb]: true,
    [styles.thumbZindex4]: true,
  })

  return (
    <div className={styles.container}>
      <input
        type='range'
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const v = Math.min(+event.target.value, maxVal - 1)
          setMinVal(v)
          event.target.value = value.toString()
        }}
        onMouseUp={() => {
          console.log([minVal, maxVal])
          onChange([minVal, maxVal])
        }}
        className={inputClass1}
      />
      <input
        type='range'
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const v = Math.max(+event.target.value, minVal + 1)
          setMaxVal(v)
          event.target.value = value.toString()
        }}
        onMouseUp={() => {
          console.log([minVal, maxVal])
          onChange([minVal, maxVal])
        }}
        className={inputClass2}
      />

      <div className={styles.slider}>
        <div className={styles.sliderTrack}></div>
        <div ref={range} className={styles.sliderRange}></div>
        <div className={styles.sliderLeftValue}>{formatNumber(minVal)}</div>
        <div className={styles.sliderRightValue}>{formatNumber(maxVal)}</div>
      </div>
    </div>
  )
}

import Button from '../../components/Button/Button'
import styles from './ContactUs.module.scss'
import React, { useEffect, useState } from 'react'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useMutation } from '@tanstack/react-query'
import { sendContactUs } from '../../api/api'
import { z, ZodError } from 'zod'

// Define ContactUsSchema correctly
export const ContactUsSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  email: z.string().email({ message: 'Invalid email address' }),
  message: z.string().min(1, { message: 'Message is required' }),
})

export default function ContactUs() {
  const { data: user } = useCurrentUser()
  const [name, setName] = useState(user?.firstName || '')
  const [email, setEmail] = useState(user?.email || '')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  useEffect(() => {
    if (user) {
      setName(user.firstName || '')
      setEmail(user.email || '')
    }
  }, [user])

  const contactMutation = useMutation({
    mutationFn: sendContactUs,
  })

  const handleSend = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const contactForm = {
      name,
      email,
      message,
    }
    try {
      ContactUsSchema.parse(contactForm)
      setErrors({})
      contactMutation.mutate(contactForm)
    } catch (err) {
      if (err instanceof ZodError) {
        const newErrors: { [key: string]: string } = {}
        err.issues.forEach(issue => {
          newErrors[issue.path[0] as string] = issue.message
        })
        setErrors(newErrors)
        const firstError = err.issues[0].message
        const foundElement = Array.from(document.querySelectorAll('p')).find(el => el.textContent === firstError)
        foundElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        })
      }
    }
  }

  const handleInputChange = (field: string, value: string) => {
    setErrors(prev => {
      const newErrors = { ...prev }
      delete newErrors[field]
      return newErrors
    })

    switch (field) {
      case 'name':
        setName(value)
        break
      case 'email':
        setEmail(value)
        break
      case 'message':
        setMessage(value)
        break
    }
  }

  return (
    <div className={styles.dialog}>
      <h2 className={styles.title}>Contact us</h2>
      <div className={styles.main}>
        <div>
          <label>Name*</label>
          <input value={name} onChange={e => handleInputChange('name', e.target.value)} />
          {errors.name && <p className={styles.error}>{errors.name}</p>}
        </div>
        <div>
          <label>Email*</label>
          <input value={email} onChange={e => handleInputChange('email', e.target.value)} />
          {errors.email && <p className={styles.error}>{errors.email}</p>}
        </div>
        <div className={styles.message}>
          <label>Message*</label>
          <textarea value={message} onChange={e => handleInputChange('message', e.target.value)} />
          {errors.message && <p className={styles.error}>{errors.message}</p>}
        </div>
        <div className={styles.btnSave}>
          <Button text='Send' medium primary onClick={e => handleSend(e)} />
        </div>
      </div>
    </div>
  )
}

// src/components/InfoApplicationPanel/InfoApplicationPanel.tsx
import React from 'react'
import styles from './InfoApplicationPanel.module.scss'
import Icon from '../Icon/Icon'
import Button from '../Button/Button'
import userPic from '../../assets/user_candidate_Profile1.png'
import { JobApplicationWithCandidate } from '../../schemas/schemas'

interface InfoApplicationPanelProps {
  isOpen: boolean
  onClose: () => void
  application: JobApplicationWithCandidate
}

const InfoApplicationPanel: React.FC<InfoApplicationPanelProps> = ({ isOpen, onClose, application }) => {
  // Function to handle the profile navigation
  const handleProfileClick = () => {
    if (application?.candidate?.id) {
      window.location.href = `/profile/${application.candidate.id}`
    }
  }

  return (
    <div className={`${styles.overlay} ${isOpen ? styles.show : ''}`} onClick={onClose}>
      <div className={`${styles.panel} ${isOpen ? '' : styles.closed}`} onClick={e => e.stopPropagation()}>
        <div className={styles.panelTop}>
          <button className={styles.closeButton} onClick={onClose}>
            <Icon iconName='arrowBack' size={15} />
          </button>
          {/* <span className={styles.linkToProfile} onClick={handleProfileClick}>
            Open profile
            <Icon iconName={'openInNewWindow'} size={20} />
          </span> */}
        </div>
        {/* ---------------------------------- */}
        <div className={styles.top}>
          <img className={styles.profilePicture} src={application?.candidate?.avatar || userPic} alt='Profile' />
          <div className={styles.profileDetails}>
            <div className={styles.name}>
              {application?.candidate?.firstName} {application?.candidate?.lastName}
            </div>
            {application?.candidate?.location && (
              <div className={styles.location}>
                <Icon iconName={'arrowOrange'} size={12} />
                <span>{application?.candidate?.location}</span>
              </div>
            )}
          </div>
          <div>
            {application?.resume ? (
              <Button
                primary
                medium
                text='Download CV'
                onClick={() => window.open(application?.resume ?? '#', '_blank')}
              />
            ) : (
              <Button primary medium text='See Profile' onClick={handleProfileClick} />
            )}
          </div>
        </div>
        {/* ---------------------------------- */}
        <div className={styles.about}>
          <h3>About</h3>
          <p>{application?.candidate?.about ?? 'No info provided'}</p>
        </div>
        {/* ---------------------------------- */}
        <div className={styles.about}>
          <h3>Skills</h3>
          {application?.candidate?.skills ? (
            <ul>
              {application.candidate.skills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          ) : (
            <p>No skills provided</p>
          )}
        </div>
        {/* ---------------------------------- */}
        <div className={styles.about}>
          <h3>Relevant Experience</h3>
          <p>No relevant experience provided</p>
          {/* <p>{application?.candidate?.relevantExperience ?? 'No relevant experience provided'}</p> */}
        </div>
      </div>
    </div>
  )
}

export default InfoApplicationPanel

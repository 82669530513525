import { useQuery } from '@tanstack/react-query'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useParams } from 'react-router-dom'
import { ZodError, z } from 'zod'
import { getJob } from '../../api/api'
import AutocompleteSearch from '../../components/AutocompleteSearch/AutocompleteSearch'
import Button from '../../components/Button/Button'
import Dropdown, { Color } from '../../components/Dropdown/Dropdown'
import Input, { Size } from '../../components/Input/Input'
import Spinner from '../../components/Spinner/Spinner'
import { AppContext, AppContextInterface } from '../../context/AppContext'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useJobMutation } from '../../hooks/useJobMutation'
import { useJobTitles } from '../../hooks/useJobTitles'
import { JobCreate } from '../../schemas/schemas'
import { TOOLBAR_CONFIG } from '../../utils/editor'
import './Calendar.css'
import './DatePicker.css'
import { Screen } from './EmployerPostNewJob'
import styles from './EmployerPostNewJob.module.scss'

interface Props {
  setScreen: Dispatch<SetStateAction<Screen>>
}

const JobCreateSchema = z
  .object({
    id: z.number().optional(),
    title: z.string().min(1, { message: 'Please enter qualification' }),
    industry: z.string().min(1, { message: 'Please select an industry' }),
    jobType: z.string().min(1, { message: 'Please select a job type' }),
    payFrom: z.number({ required_error: 'Please enter a min value' }),
    payTo: z.number({ required_error: 'Please enter a max value' }),
    nrVacancies: z.number({ required_error: 'Please enter number of vacancies available' }),
    experience: z.number({ required_error: 'Please enter number of years required' }),
    careerLevel: z.string({ required_error: 'Please enter career level' }).min(1),
    location: z.string({ required_error: 'Please enter job location' }).min(2),
    deadline: z.string({ required_error: 'Please enter a date' }),
    qualification: z.string({ required_error: 'Please enter qualification' }),
    description: z.string({ required_error: 'Please enter a concise description' }),
    responsibilities: z.string({ required_error: 'Please enter responsibilities' }),
    requirements: z.string({ required_error: 'Please enter skill & experience' }),
  })
  .refine(data => data.payFrom <= data.payTo, {
    message: 'The max value should be greater than min',
    path: ['payTo'],
  })

export default function EmployerPostNewJobForm({ setScreen }: Props) {
  const { isLoading, data: user } = useCurrentUser()
  const jobId = Number(useParams().jobId)

  const numericJobId = jobId ? Number(jobId) : null

  // Query
  const { isLoading: jobFetching, data: editJobData } = useQuery({
    queryKey: ['editJob', jobId],
    queryFn: () => getJob(jobId),
    enabled: !!jobId,
  })

  const jobMutation = useJobMutation()

  const context: AppContextInterface = useContext(AppContext)
  const [jobTitle, setJobTitle] = useState<string>()
  const [jobIndustry, setJobIndustry] = useState<string>()
  const [jobType, setJobType] = useState<string>()
  const [payFrom, setPayFrom] = useState<number>()
  const [payTo, setPayTo] = useState<number>()
  const [nrVacancies, setNrVacancies] = useState<number>()
  const [experienceYears, setExperienceYears] = useState<number>()
  const [careerLevel, setCareerLevel] = useState<string>()
  const [jobLocation, setJobLocation] = useState<string>()
  const [descriptionEditorState, setDescriptionEditorState] = useState(EditorState.createWithContent(stateFromHTML('')))
  const [responsibilitiesEditorState, setResponsibilitiesEditorState] = useState(
    EditorState.createWithContent(stateFromHTML('')),
  )
  const [skillsExperienceEditorState, setSkillsExperienceEditorState] = useState(
    EditorState.createWithContent(stateFromHTML('')),
  )
  const [deadline, setDeadline] = useState<Date>()
  const [qualification, setQualification] = useState<string>()

  const [errors, setErrors] = useState<ZodError | null>()

  const { isPending: jobTitlesPending, data } = useJobTitles()
  const jobTitles = data || []
  const jobTypes = context.state.jobTypes
  const jobIndustries = context.state.industries

  useEffect(() => {
    if (numericJobId) {
      setJobTitle(editJobData?.title)
      setJobIndustry(editJobData?.industry)
      setJobType(editJobData?.jobType)
      setPayFrom(editJobData?.payFrom)
      setPayTo(editJobData?.payTo)
      setNrVacancies(editJobData?.nrVacancies)
      setExperienceYears(editJobData?.experience)
      setCareerLevel(editJobData?.careerLevel)
      setJobLocation(editJobData?.location)
      setDeadline(editJobData?.deadline ? DateTime.fromISO(editJobData.deadline).toJSDate() : undefined)
      setQualification(editJobData?.qualification)
      setResponsibilitiesEditorState(EditorState.createWithContent(stateFromHTML(editJobData?.responsibilities || '')))
      setSkillsExperienceEditorState(EditorState.createWithContent(stateFromHTML(editJobData?.requirements || '')))
    }
  }, [editJobData])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    const getHtml = (state: EditorState) => {
      const html = stateToHTML(state.getCurrentContent()).replace('<p><br></p>', '')
      return html || undefined
    }

    // JobCreate has id optional
    const job: JobCreate = {
      id: jobId || undefined,
      title: jobTitle || '',
      industry: jobIndustry || '',
      jobType: jobType || '',
      payFrom: payFrom,
      payTo: payTo,
      nrVacancies: nrVacancies,
      experience: experienceYears,
      careerLevel: careerLevel,
      location: jobLocation,
      description: getHtml(descriptionEditorState),
      responsibilities: getHtml(responsibilitiesEditorState),
      requirements: getHtml(skillsExperienceEditorState),
      deadline: deadline ? DateTime.fromJSDate(deadline).toISODate() : undefined,
      qualification: qualification,
      companyId: user?.companyId,
      creatorId: user?.id,
    }

    try {
      JobCreateSchema.parse(job)
      setErrors(null)
      jobMutation.mutate(job) // will create or update based on job.id
      if (!jobId) setScreen(Screen.SUCCESS) // after creating new job only
    } catch (err) {
      if (err instanceof z.ZodError) {
        setErrors(err)
      }
    }
  }

  // scroll to error
  useEffect(() => {
    if ((errors?.issues?.length ?? 0) > 0) {
      const firstError = errors?.issues[0].message
      const foundElement = Array.from(document.querySelectorAll('p')).find(el => el.textContent === firstError)

      foundElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }, [errors])

  return (
    <>
      {(isLoading || jobFetching || jobTitlesPending || jobMutation.isPending) && <Spinner />}
      <div>
        <h1 className={styles.title}>{numericJobId ? 'Edit Job Posting!' : 'Post a New Job!'}</h1>
        <form className={styles.form} onSubmit={handleSubmit}>
          <p className={styles.intro}>
            Please provide complete information regarding the <span>{numericJobId ? 'existing' : 'new'}</span> job
            posting for your company.
          </p>
          <div className={styles.concise}>
            <div className={styles.contactDetails}>
              <div className={styles.jobTitles}>
                <label>Job Title*</label>
                <AutocompleteSearch
                  className={styles.jobTitleAutoComplete}
                  placeholder='Job title, keywords...'
                  data={jobTitles ?? []}
                  onChange={value => setJobTitle(value)}
                  value={jobTitle || ''}
                  // errors={errors?.formErrors.fieldErrors.title}
                  bgColor={Color.Gray}
                />
                {errors?.formErrors?.fieldErrors?.title &&
                  errors?.formErrors?.fieldErrors?.title.map((error, index) => (
                    <p key={index} className={styles.errorMessage}>
                      {error}
                    </p>
                  ))}
              </div>

              <div>
                <label>Industry*</label>
                <Dropdown
                  placeholder='Choose an industry'
                  data={jobIndustries}
                  onChange={value => setJobIndustry(value)}
                  value={jobIndustries.find(it => it.value === jobIndustry)?.id || ''}
                  bgColor={Color.Gray}
                />
                {errors?.formErrors?.fieldErrors?.industry &&
                  errors?.formErrors?.fieldErrors?.industry.map((error, index) => (
                    <p key={index} className={styles.errorMessage}>
                      {error}
                    </p>
                  ))}
              </div>

              <div>
                <label>Job Type*</label>
                <Dropdown
                  placeholder='Select an option'
                  data={jobTypes.map(type => ({ id: type.id, value: type.value }))}
                  onChange={id => setJobType(jobTypes.find(it => it.id === id)?.value || '')}
                  value={jobTypes.find(it => it.value === jobType)?.id || ''}
                  bgColor={Color.Gray}
                />
                {errors?.formErrors?.fieldErrors?.jobType &&
                  errors?.formErrors?.fieldErrors?.jobType.map((error, index) => (
                    <p key={index} className={styles.errorMessage}>
                      {error}
                    </p>
                  ))}
              </div>

              <div>
                <label>Salary Range (min - max)*</label>
                <div className={styles.range}>
                  <Input
                    value={payFrom}
                    onChange={e => setPayFrom(Number(e.target.value))}
                    size={Size.Large}
                    bgColor={Color.Gray}
                    type={'number'}
                    min={'1'}
                    errors={errors?.formErrors?.fieldErrors?.payFrom}
                  />
                  <Input
                    value={payTo}
                    onChange={e => setPayTo(Number(e.target.value))}
                    size={Size.Large}
                    bgColor={Color.Gray}
                    type={'number'}
                    min={'1'}
                    errors={errors?.formErrors?.fieldErrors?.payTo}
                  />
                </div>
              </div>

              <div>
                <label>No. of Vacancy*</label>
                <Input
                  value={String(nrVacancies)}
                  onChange={e => setNrVacancies(Number(e.target.value))}
                  size={Size.Large}
                  bgColor={Color.Gray}
                  type={'number'}
                  min={'1'}
                  errors={errors?.formErrors?.fieldErrors?.nrVacancies}
                />
              </div>

              <div>
                <label>Experience (years)*</label>
                <Input
                  value={String(experienceYears)}
                  onChange={e => setExperienceYears(Number(e.target.value))}
                  size={Size.Large}
                  bgColor={Color.Gray}
                  type={'number'}
                  min={'0'}
                  errors={errors?.formErrors?.fieldErrors?.experience}
                />
              </div>

              <div>
                <label>Career Level*</label>
                <Input
                  placeholder='Example: Junior'
                  value={careerLevel}
                  onChange={e => setCareerLevel(e.target.value)}
                  size={Size.Large}
                  bgColor={Color.Gray}
                  errors={errors?.formErrors?.fieldErrors?.careerLevel}
                />
              </div>

              <div>
                <label>Job Location*</label>
                <Input
                  placeholder='Example: 123 Main street or Remote'
                  value={jobLocation}
                  onChange={e => setJobLocation(e.target.value)}
                  size={Size.Large}
                  bgColor={Color.Gray}
                  errors={errors?.formErrors?.fieldErrors?.location}
                />
              </div>

              <div>
                <label>Deadline*</label>
                <DatePicker
                  className={errors?.formErrors?.fieldErrors?.deadline && styles.errorBorder}
                  onChange={d => setDeadline(d as Date)}
                  value={deadline}
                  minDate={new Date()}
                />
                {errors?.formErrors?.fieldErrors?.deadline &&
                  errors?.formErrors?.fieldErrors?.deadline.map((error, index) => (
                    <p key={index} className={styles.errorMessage}>
                      {error}
                    </p>
                  ))}
              </div>
            </div>

            <div>
              <label>Qualification*</label>
              <Input
                placeholder='Example: Bachelor Degree'
                value={qualification}
                onChange={e => setQualification(e.target.value)}
                size={Size.Large}
                bgColor={Color.Gray}
                errors={errors?.formErrors?.fieldErrors?.qualification}
              />
            </div>

            <label>Job Description*</label>
            <Editor
              editorState={descriptionEditorState}
              toolbarClassName={styles.toolbarClassName}
              wrapperClassName={
                errors?.formErrors?.fieldErrors?.description ? styles.wrapperClassNameError : styles.wrapperClassName
              }
              editorClassName={styles.editorClassName}
              onEditorStateChange={state => setDescriptionEditorState(state)}
              toolbar={TOOLBAR_CONFIG}
            />
            {errors?.formErrors?.fieldErrors?.description &&
              errors?.formErrors?.fieldErrors?.description.map((error, index) => (
                <p key={index} className={styles.errorMessage}>
                  {error}
                </p>
              ))}

            <label>Key Responsibilities*</label>
            <Editor
              editorState={responsibilitiesEditorState}
              toolbarClassName={styles.toolbarClassName}
              wrapperClassName={
                errors?.formErrors?.fieldErrors?.responsibilities
                  ? styles.wrapperClassNameError
                  : styles.wrapperClassName
              }
              editorClassName={styles.editorClassName}
              onEditorStateChange={state => setResponsibilitiesEditorState(state)}
              toolbar={TOOLBAR_CONFIG}
            />
            {errors?.formErrors?.fieldErrors?.responsibilities &&
              errors?.formErrors?.fieldErrors?.responsibilities.map((error, index) => (
                <p key={index} className={styles.errorMessage}>
                  {error}
                </p>
              ))}

            <label>Skill & Experience*</label>
            <Editor
              editorState={skillsExperienceEditorState}
              toolbarClassName={styles.toolbarClassName}
              wrapperClassName={
                errors?.formErrors?.fieldErrors?.requirements ? styles.wrapperClassNameError : styles.wrapperClassName
              }
              editorClassName={styles.editorClassName}
              onEditorStateChange={state => setSkillsExperienceEditorState(state)}
              toolbar={TOOLBAR_CONFIG}
            />
            {errors?.formErrors?.fieldErrors?.requirements &&
              errors?.formErrors?.fieldErrors?.requirements.map((error, index) => (
                <p key={index} className={styles.errorMessage}>
                  {error}
                </p>
              ))}

            <Button className={styles.saveBtn} primary medium basic text={'Save'} disabled={jobMutation.isPending} />
          </div>
        </form>
      </div>
    </>
  )
}

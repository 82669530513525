import React, { useState } from 'react'
import styles from './LoginRegisterDialog.module.scss'
import Dialog from 'rc-dialog'
import 'rc-dialog/assets/index.css'
import Input, { Color } from '../Input/Input'
import Button from '../Button/Button'
import candidateImg from '../Icon/svg icons/profileFillNone.svg'
import candidateImgWhite from '../Icon/svg icons/profileFillWhite.svg'
import bagWhite from '../Icon/svg icons/bagWhite.svg'
import bag from '../Icon/svg icons/bagBlue.svg'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import axios, { AxiosError } from 'axios'
import Icon from '../Icon/Icon'
import { useLoginMutation } from '../../hooks/useLoginMutation'
import {
  LoginRequest,
  LoginRequestErrors,
  RegisterRequest,
  RegisterRequestErrors,
  UserType,
  loginSchema,
  registerSchema,
} from '../../schemas/schemas'
import { useRegistrationMutation } from '../../hooks/useRegistrationMutation'
import Spinner from '../Spinner/Spinner'
import LoginRegVerify from '../LoginRegVerify/LoginRegVerify'
import { forgotPasswordRequest, forgotPasswordVerify, forgotPasswordReset } from '../../api/api'
import { useMutation } from '@tanstack/react-query'
import { useSignupVerifyMutation } from '../../hooks/useSignupVerifyMutation'

interface Props {
  closeModal: () => void
}

export enum Screen {
  LOGIN = 'LOGIN',
  SIGN_UP = 'SIGN_UP',
  SIGN_UP_VERIFY = 'SIGN_UP_VERIFY',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
  FORGOT_PASSWORD_EMAIL = 'FORGOT_PASSWORD_EMAIL',
  FORGOT_PASSWORD_VERIFY = 'FORGOT_PASSWORD_VERIFY',
  FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET',
  FORGOT_PASSWORD_RESET_SUCCESS = 'FORGOT_PASSWORD_RESET_SUCCESS',
  // MFA_PHONE,
  SUCCESS_LOGIN = 'SUCCESS_LOGIN',
}

export default function LoginRegisterDialog({ closeModal }: Props) {
  // const { isLoading, isError, data: user, error: userError } = useCurrentUser()
  const [screen, setScreen] = useState<Screen>(Screen.LOGIN)
  const navigate = useNavigate()

  const [formData, setFormData] = useState<LoginRequest>({ email: '', password: '', rememberMe: false })
  const [errors, setErrors] = useState<LoginRequestErrors>({})
  const [verificationError, setVerificationError] = useState('')

  const loginMutation = useLoginMutation()
  const registrationMutation = useRegistrationMutation()
  const registerVerifyMutation = useSignupVerifyMutation()
  const forgotPasswordRequestMutation = useMutation({
    mutationFn: forgotPasswordRequest,
  })
  const forgotPasswordVerifyMutation = useMutation({
    mutationFn: forgotPasswordVerify,
  })
  const forgotPasswordResetMutation = useMutation({
    mutationFn: forgotPasswordReset,
  })

  const [forgotPasswordToken, setForgotPasswordToken] = useState('')

  const [regData, setRegData] = useState<RegisterRequest>({
    userType: UserType.EMPLOYER,
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [regErrors, setRegErrors] = useState<RegisterRequestErrors>({})
  const [resetPasswordErrors, setResetPasswordErrors] = useState<RegisterRequestErrors>({
    password: '',
    confirmPassword: '',
  })

  const resetPasswordSchema = z
    .object({
      password: z.string().min(8, 'Password must be at least 8 characters.'),
      confirmPassword: z.string(),
    })
    .refine(data => data.password === data.confirmPassword, {
      message: "Passwords don't match",
      path: ['confirmPassword'], // This specifies that the error should be attached to the confirmPassword field
    })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleChangeReg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setRegData({ ...regData, [name]: value })
  }

  async function handleSignUp(e: React.SyntheticEvent) {
    e.preventDefault()
    try {
      registerSchema.parse(regData)
      await registrationMutation.mutateAsync(regData)
      setScreen(Screen.SIGN_UP_VERIFY)
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: RegisterRequestErrors = {}
        error.errors.forEach(err => {
          if (err.path[0]) newErrors[err.path[0] as keyof RegisterRequest] = err.message
        })
        setRegErrors(newErrors)
      } else if (axios.isAxiosError(error)) {
        // Safely check if it's an AxiosError
        const axiosError = error as AxiosError // Cast the error to AxiosError
        if (axiosError.response) {
          if (axiosError.response.status === 409 || axiosError.response.status === 422) {
            setRegErrors({ email: 'This email is already registered. Please sign in.' })
          } else {
            setRegErrors({ email: axiosError.message })
          }
        } else {
          setRegErrors({ email: 'An unknown error occurred. Please try again.' })
        }
      } else {
        // Handle non-Axios errors here
        setRegErrors({ email: 'An error occurred. Please try again.' })
      }
    }
  }

  const handleSignUpVerify = async (code: string) => {
    try {
      await registerVerifyMutation.mutateAsync({ email: regData.email, code })
      setVerificationError('') // Clear any existing errors
      setScreen(Screen.SIGN_UP_SUCCESS)
    } catch (err) {
      setVerificationError('Incorrect verification code. Please try again.')
    }
  }

  async function handleSignUpResendVerificationCode() {
    await forgotPasswordRequestMutation.mutateAsync(regData.email)
  }

  const handleLogIn = (e: React.SyntheticEvent) => {
    e.preventDefault()
    try {
      loginSchema.parse(formData)
      loginMutation.mutate(formData, {
        onSuccess: closeModal,
        onError: (error: Error) => {
          if (error instanceof AxiosError) {
            const errMessage = String(error.response?.data.detail) || error.message
            if (errMessage.includes('Email')) {
              setErrors({ email: errMessage })
            } else if (errMessage.includes('password')) {
              setErrors({ password: errMessage })
            } else {
              setErrors({ email: errMessage })
            }
          }
        },
      })
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: LoginRequestErrors = {}
        error.errors.forEach(err => {
          if (err.path[0]) newErrors[err.path[0] as keyof LoginRequest] = err.message
        })
        setErrors(newErrors)
      }
    }
  }

  async function handleForgotPasswordEmail() {
    try {
      // Validate the email using Zod schema
      z.string().min(1, 'Email is required').email('Invalid email format').parse(formData.email)
      setErrors({ ...errors, email: '' }) // Clear any existing email errors
      await forgotPasswordRequestMutation.mutateAsync(formData.email)
      setScreen(Screen.FORGOT_PASSWORD_VERIFY) // Navigate to VERIFY screen only if email is valid
    } catch (error) {
      if (error instanceof z.ZodError) {
        // Update the error state with the validation error message
        setErrors({ ...errors, email: error.errors[0].message })
      } else if (axios.isAxiosError(error)) {
        setErrors({ ...errors, email: error.message })
      }
    }
  }

  async function handleForgotPasswordResendVerificationCode() {
    await forgotPasswordRequestMutation.mutateAsync(formData.email)
  }

  const handleForgotPasswordVerify = async (code: string) => {
    try {
      const { token } = await forgotPasswordVerifyMutation.mutateAsync({ email: formData.email, code })
      setForgotPasswordToken(token)
      setVerificationError('') // Clear any existing errors
      setScreen(Screen.FORGOT_PASSWORD_RESET)
    } catch (err) {
      setVerificationError('Incorrect verification code. Please try again.')
    }
  }

  const handleResetPasswordSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    try {
      // Validate using the Zod schema
      resetPasswordSchema.parse({
        password: regData.password,
        confirmPassword: regData.confirmPassword,
      })

      // Clear previous errors
      setResetPasswordErrors({ password: '', confirmPassword: '' })

      await forgotPasswordResetMutation.mutateAsync({
        newPassword: regData.password,
        token: forgotPasswordToken,
      })
      setScreen(Screen.FORGOT_PASSWORD_RESET_SUCCESS)
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors = error.flatten().fieldErrors
        setResetPasswordErrors({
          password: newErrors.password?.[0] || '',
          confirmPassword: newErrors.confirmPassword?.[0] || '',
        })
      } else if (axios.isAxiosError(error)) {
        setResetPasswordErrors({ password: error.message })
      } else {
        console.error('Failed to update password', error)
        setResetPasswordErrors({ password: 'Failed to update password. Try again.' })
      }
    }
  }

  function handleSignUpSuccess() {
    closeModal()

    // Navigate to the specified destination
    if (regData.userType == UserType.CANDIDATE) navigate('/search')
    else navigate('/emp/company-profile')
  }

  return (
    <div>
      {(loginMutation.isPending || registrationMutation.isPending) && <Spinner />}

      {screen == Screen.LOGIN && (
        <Dialog title='Login to Pavedit' onClose={closeModal} visible className={styles.center} maskAnimation='fade'>
          <form onSubmit={handleLogIn}>
            <div className={styles.auto}>
              <p className={styles.title}>Email Address*</p>
              <Input bgColor={Color.Gray} name='email' value={formData.email} onChange={handleChange} />
              {errors.email && <div className={styles.error}>{errors.email}</div>}
            </div>
            <div className={styles.auto}>
              <p className={styles.title}>Password*</p>
              <Input
                name='password'
                bgColor={Color.Gray}
                type='password'
                value={formData.password}
                onChange={handleChange}
              />
              {errors.password && <div className={styles.error}>{errors.password}</div>}
            </div>
            <div className={styles.options}>
              <div className={styles.remember}>
                <input
                  type='checkbox'
                  id='remember'
                  checked={formData.rememberMe}
                  onChange={e => setFormData({ ...formData, rememberMe: e.target.checked })}
                />
                <label htmlFor='remember' className={styles.title}>
                  Remember Me
                </label>
              </div>
              {/* forgot - email -code-reset */}
              <span className={styles.forgot} onClick={() => setScreen(Screen.FORGOT_PASSWORD_EMAIL)}>
                Forgot your password?
              </span>
            </div>
            <button type='submit' className={styles.logIn} disabled={loginMutation.isPending}>
              Log In
            </button>
          </form>
          <div className={styles.new_account}>
            <span className={styles.text}>Don't have an account? </span>
            <span onClick={() => setScreen(Screen.SIGN_UP)} className={styles.sign_up}>
              Sign Up
            </span>
          </div>
          {/* <div className={styles.or}>
            <span>-------------------------------------------- </span>
            <span>Or with</span>
            <span> -------------------------------------------</span>
          </div> */}
          {/* <div className={styles.other}>
            <div className={styles.button}>
              <img src={googleLogo} alt='googleLogo' />
              <span>Continue with Google</span>
            </div>
            <div className={styles.button}>
              <img src={linkedinLogo} alt='googleLogo' />
              <span>Continue with LinkedIn</span>
            </div>
          </div> */}
        </Dialog>
      )}

      {screen == Screen.SIGN_UP && (
        <Dialog
          title='Create a Free Pavedit Account'
          onClose={closeModal}
          className={styles.center}
          maskAnimation='fade'
          visible
        >
          <div className={styles.other}>
            {regData.userType == UserType.EMPLOYER && (
              <>
                <div
                  className={styles.button1}
                  onClick={() => setRegData({ ...regData, userType: UserType.CANDIDATE })}
                >
                  <img src={candidateImg} alt='candidateImg' />
                  <span>Candidate</span>
                </div>
                <div className={styles.button2}>
                  <img src={bagWhite} alt='white bag' />
                  <span>Employer</span>
                </div>
              </>
            )}

            {regData.userType == UserType.CANDIDATE && (
              <>
                <div className={styles.button2}>
                  <img src={candidateImgWhite} alt='candidateImgWhite' />
                  <span>Candidate</span>
                </div>
                <div className={styles.button1} onClick={() => setRegData({ ...regData, userType: UserType.EMPLOYER })}>
                  <img src={bag} alt='bag' />
                  <span>Employer</span>
                </div>
              </>
            )}
          </div>

          <form onSubmit={handleSignUp}>
            <div className={styles.auto}>
              <p className={styles.title}>Email Address*</p>
              <Input bgColor={Color.Gray} name='email' value={regData.email} onChange={handleChangeReg} />
              {regErrors.email && <div className={styles.error}>{regErrors.email}</div>}
            </div>
            <div className={styles.auto}>
              <p className={styles.title}>Password*</p>
              <Input
                bgColor={Color.Gray}
                type='password'
                name='password'
                value={regData.password}
                onChange={handleChangeReg}
              />
              {regErrors.password && <div className={styles.error}>{regErrors.password}</div>}
            </div>
            <div className={styles.auto}>
              <p className={styles.title}>Confirm Password*</p>
              <Input
                bgColor={Color.Gray}
                type='password'
                name='confirmPassword'
                value={regData.confirmPassword}
                onChange={handleChangeReg}
              />
              {regErrors.confirmPassword && <div className={styles.error}>{regErrors.confirmPassword}</div>}
            </div>
            <button type='submit' className={styles.logIn} disabled={registrationMutation.isPending}>
              Sign Up
            </button>
          </form>
          {/* <div className={styles.or}>
            <span>-------------------------------------------- </span>
            <span>Or with</span>
            <span>--------------------------------------------</span>
          </div> */}
          {/* <div className={styles.other}>
            <div className={styles.button}>
              <img src={googleLogo} alt='googleLogo' />
              <span>Continue with Google</span>
            </div>
            <div className={styles.button}>
              <img src={linkedinLogo} alt='googleLogo' />
              <span>Continue with LinkedIn</span>
            </div>
          </div> */}
          <div className={styles.new_account}>
            <span>Already have an account?</span>
            <span onClick={() => setScreen(Screen.LOGIN)} className={styles.sign_up}>
              Sign in
            </span>
          </div>
        </Dialog>
      )}
      {screen === Screen.SIGN_UP_VERIFY && (
        <LoginRegVerify
          email={regData.email}
          onVerify={handleSignUpVerify}
          onRequestResend={handleSignUpResendVerificationCode}
          verificationError={verificationError}
          onClose={closeModal}
        />
      )}

      {screen == Screen.SIGN_UP_SUCCESS && (
        <Dialog title='Success' onClose={closeModal} visible className={styles.center} maskAnimation='fade'>
          <div className={styles.success_window}>
            <Icon iconName={'success'} size={100} />
            <p id='message'>Congratulations, your account has been successfully created.</p>
            <Button primary medium text='Continue' onClick={() => handleSignUpSuccess()} />
          </div>
        </Dialog>
      )}
      {screen == Screen.FORGOT_PASSWORD_EMAIL && (
        <Dialog
          title='Forgot your password'
          onClose={closeModal}
          visible
          className={styles.center}
          maskAnimation='fade'
        >
          <div className={styles.success_window}>
            <p id='message'>Please enter the email address you'd like your password reset information sent to.</p>
            <div className={styles.emailContainer}>
              <p className={styles.title}>Email Address*</p>
              <Input bgColor={Color.Gray} name='email' value={formData.email} onChange={handleChange} />
              {errors.email && <div className={styles.error}>{errors.email}</div>}
            </div>
            <button
              type='submit'
              className={styles.logIn}
              disabled={forgotPasswordRequestMutation.isPending}
              onClick={handleForgotPasswordEmail}
            >
              Reset Password
            </button>
          </div>
        </Dialog>
      )}
      {screen === Screen.FORGOT_PASSWORD_VERIFY && (
        <LoginRegVerify
          email={formData.email}
          onVerify={handleForgotPasswordVerify}
          onRequestResend={handleForgotPasswordResendVerificationCode}
          verificationError={verificationError}
          onClose={closeModal}
        />
      )}

      {screen == Screen.FORGOT_PASSWORD_RESET && (
        <Dialog title='Reset password' onClose={closeModal} visible className={styles.center} maskAnimation='fade'>
          <p>Please enter a new password. Must be at least 8 characters.</p>
          <form onSubmit={handleResetPasswordSubmit}>
            <div className={styles.auto}>
              <p className={styles.title}>Password*</p>
              <Input
                bgColor={Color.Gray}
                type='password'
                name='password'
                value={regData.password}
                onChange={handleChangeReg}
              />
              {resetPasswordErrors.password && <div className={styles.error}>{resetPasswordErrors.password}</div>}
            </div>
            <div className={styles.auto}>
              <p className={styles.title}>Confirm Password*</p>
              <Input
                bgColor={Color.Gray}
                type='password'
                name='confirmPassword'
                value={regData.confirmPassword}
                onChange={handleChangeReg}
              />
              {resetPasswordErrors.confirmPassword && (
                <div className={styles.error}>{resetPasswordErrors.confirmPassword}</div>
              )}
            </div>
            <button type='submit' className={styles.logIn} disabled={forgotPasswordResetMutation.isPending}>
              Reset Password
            </button>
          </form>
        </Dialog>
      )}

      {screen == Screen.FORGOT_PASSWORD_RESET_SUCCESS && (
        <Dialog title='Success' onClose={closeModal} visible className={styles.center} maskAnimation='fade'>
          <div className={styles.success_window}>
            <Icon iconName={'success'} size={100} />
            <p id='message'>Password was successfully updated.</p>
            <Button primary medium text='Continue' onClick={() => setScreen(Screen.LOGIN)} />
          </div>
        </Dialog>
      )}
    </div>
  )
}
